import React from 'react';
import { Link } from 'react-router-dom';
import apiManager from '../../utils/apiManager';
import UserLogo from '../SVGs/user';
import WelcomeBack from '../WelcomeBack/index';
import WelcomeBackOTP from '../WelcomBackOTP/index'
import ForgotPassword from '../ForgotPasswordComponent/forgotpassword';
import '../../../public/styles/userInfo/userInfo.scss';
import appCookie from '../../utils/cookie';
import { getCookie, getReleventReduxState } from '../../utils/utilityManager';
import RegisterModalData from '../RegisterComponent/registerModalData';
import { storeConfiguration, userDetailAPI } from '../../../public/constants/constants';
import { logoutTheUser } from '../../utils/initialManager';
import { MY_ORDER, MANAGE_ADDRESSES, LOGIN_REGISTER, HELLO_GUEST } from '../../constants/app/primitivesConstants';
import { regexEmail, regexMobileNo } from '../../utils/validationManager';
import RegisterOTP from '../RegisterComponent/registerOTP'
import {
  accessTokenCookie,
  wishlistDataCookie,
  wishlistIdCookie,
} from '../../../public/constants/constants';
import connect from 'react-redux/src/connect/connect'

class UserAccInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: '',
      isLoading: true,
      errors: null,
      loginStatus: 'Login/Register',
      userType: 'Hello Guest!',
      showLoginRegisterMain: false,
      showForgotPassword: false,
      showRegister: false,
      loginLogoutBtnItem: null,
      isFromWishlist: false,
      userName: null,
      logonId: null,
    };
  }

  resetLoginValues() {
    if (this.props.resetCallbackPro) {
      this.props.resetCallbackPro();
    }
    this.setState({
      showLoginRegisterMain: false,
      showForgotPassword: false,
      showRegister: false,
    });
  }

  onLoginRegisterClick() {
    appCookie.set('wishListUniqueId', '' , 365 * 24 * 60 * 60 * 1000);
    this.setState({ showLoginRegisterMain: true }); 
  }

  welcomeBackCallback(fromForgot) {
    // Only to manage show and hide state
    if (fromForgot) {
      this.setState({
        showForgotPassword: true,
        showLoginRegisterMain: false,
      });
    } else {
      this.setState({
        showRegister: true,
        showLoginRegisterMain: false,
      });
    }
  }

  forgotPasswordCallback(isShowRegister) { // isShowRegister active for JIRA-902
    this.setState({
      showLoginRegisterMain: true,
      showForgotPassword: false,
    });
    if (isShowRegister) {
      this.welcomeBackCallback(false);
    }

  }

  registerCallback() {
    this.setState({
      showLoginRegisterMain: true,
      showRegister: false,
    });
  }

  getUserDetails() {
    apiManager
      .get(userDetailAPI, {
        headers: { profile: 'summary' },
      })
      .then(response => {
        if (!response.data.data.hasOwnProperty('name') && response.data.data.name === undefined) {
          this.onLogoutClick();
        }
        else {
          const username = String(response.data.data.name);
          if (response.data.data.pincode && response.data.data.pincode !== '' && appCookie.get('pincodeUpdated') !== 'true') {
            appCookie.set('pincode', response.data.data.pincode, 365 * 24 * 60 * 60 * 1000);
            appCookie.set('pincodeUpdated', true, 365 * 24 * 60 * 60 * 1000);
          }

          if (window.location.pathname.includes('/furniture-online-')) {
            if (response.data.data.pincode && response.data.data.pincode !== '') {
              appCookie.set('pincode', response.data.data.pincode, 365 * 24 * 60 * 60 * 1000);
              appCookie.set('pincodeUpdated', true, 365 * 24 * 60 * 60 * 1000);
            }
          }
          this.setState({
            userName: `Hello ${username.split(' ')[0]}`,
            logonId: response.data.data.logonID,
          });

          // document.cookie = `name=${response.data.data.name};path=/;expires=''`;
          // document.cookie = `loginID=${response.data.data.logonID};path=/;expires=''`;

          appCookie.set('name', response.data.data.name, 365 * 24 * 60 * 60 * 1000);
          appCookie.set('loginID', response.data.data.logonID, 365 * 24 * 60 * 60 * 1000);

          const updatedIntercomSettings = {};
          const loginId = appCookie.get('loginID');
          if (regexEmail.test(loginId)) {
            updatedIntercomSettings.email = loginId;
          } else if (regexMobileNo.test(loginId)) {
            updatedIntercomSettings.email = loginId;
            updatedIntercomSettings.phone = loginId;
          }
         // window.Intercom('update', updatedIntercomSettings);

          this.showLoginStatus();
          this.props.updateUserProfile(response.data.data.name);
        }

      })
      .catch(error => {
        // return null;
      });
  }

  showLoginStatus() {
    const getLoginCookie = appCookie.get('isLoggedIn');
    if (getCookie('isLoggedIn') === 'true') {
      (this.state.userType = (
        <>
          <li className="listItem">
            <Link to={{ pathname: '/myAccount', state: { from: 'myprofile' } }}>
              <a onClick={this.onMyProfileClick} className="dropDown">
                My Profile
              </a>
            </Link>
          </li>
          <li className="listItem">
            <Link to={{ pathname: '/myAccount', state: { from: 'myorder' } }}>
              <a className="dropDown">{MY_ORDER}</a>
            </Link>
          </li>
          <li className="listItem">
            <Link to={{ pathname: '/myAccount', state: { from: 'address' } }}>
              <a className="dropDown">{MANAGE_ADDRESSES}</a>
            </Link>
          </li>

        </>
      )),
      (this.state.loginStatus = (
        <a className="dropDown" onClick={this.onLogoutClick.bind(this)}>
            Sign Out
        </a>
      ));
    } else {
      this.setState({
        userType:
          <li className="listItemUnSelected">
            <a className="dropDown">{HELLO_GUEST}</a>
          </li>
        ,
        loginStatus:
          <a
            className="dropDown"
            onClick={this.onLoginRegisterClick.bind(this)}
          >
            {' '}
            {LOGIN_REGISTER}
          </a>,

      });

    }
  }

  onLogoutClick() {
    // appCookie.set('pincodeUpdated', false, 365 * 24 * 60 * 60 * 1000);
   // logoutTheUser();
   if(getCookie("isLoggedIn") == "true")
    {
      logoutTheUser();
        document.cookie = `${accessTokenCookie}=;path=/;expires=''`;
        const json_str = JSON.stringify([]);
        document.cookie = `${wishlistDataCookie}=${json_str};path=/;expires=''`;
        document.cookie = `${wishlistIdCookie}=;path=/;expires=''`;
        document.cookie = `name=;path=/;expires=''`;
        document.cookie = `loginID=;path=/;expires=''`;
        appCookie.set('isLoggedIn', false, 365 * 24 * 60 * 60 * 1000);
        appCookie.set(`adrID=;path=/;expires=''`)
      window.location.href = "/";
    }
  }

  componentDidMount() {
    // alert("hello")

    this.fromWishlist(this.props.fromWishlistPro);
    if (getCookie('isLoggedIn') === 'true') {
      this.getUserDetails();
    }
    this.showLoginStatus();
    if (window.location.href ===  `https://www.godrejinterio.com/forgotPassword`) {
      this.setState({
        showForgotPassword: true,
      })
    }
    // alert('hii')
    document.addEventListener('registerPopupEVent', event=>{
       if (getCookie('isLoggedIn') === 'false' || getCookie('isLoggedIn') === '') {
      if(!this.state.showLoginRegisterMain)
        this.setState({
          showRegister: true,
          showLoginRegisterMain: false
        })
      }
    })
    document.addEventListener('loginPopupEVent', event=>{
      console.log('userAccInfo')
      if (getCookie('isLoggedIn') === 'false' || getCookie('isLoggedIn') === '') {
      //  if(!this.state.showRegister)
       this.setState({
         showRegister: false,
         showLoginRegisterMain: true
       })
     }
   })
  }

  componentWillReceiveProps(nextProps) {
    this.fromWishlist(nextProps.fromWishlistPro);
    if (getCookie('isLoggedIn') === 'true') {
      this.getUserDetails();
    }
  }

  fromWishlist(data) {
    if (data) {
      this.setState({
        showLoginRegisterMain: true,
        isFromWishlist: true,
      });
    }
  }

  render() {
    let userLogoItem = null;
    let dropdownItem = null;
    if (this.props.showUserInfo) {
      userLogoItem = <UserLogo />;
      dropdownItem = (
        <ul className="userList">
          {this.state.userName !== null &&
            getCookie('isLoggedIn') === 'true' ? (
              <li className="listItem listItemUnSelected">
                <a className="dropDown">{this.state.userName}</a>
              </li>
            ) : null}
          {this.state.userType}
          <li className="listItem">{this.state.loginStatus}</li>
        </ul>
      );
    }
    return ( 
      <li className="user icons">
        {userLogoItem}
        <ul className={`welcomeDropDown ${getCookie('isLoggedIn') === 'true' ? 'userLogin' : null}`}>
          {dropdownItem}
          {this.state.showLoginRegisterMain && this.props.isOTPEnabled ? (
            <WelcomeBackOTP
              callbackPro={this.welcomeBackCallback.bind(this)}
              resetCallbackPro={this.resetLoginValues.bind(this)}
              couponData = {this.props.couponData}
            />
          ) : null}
          {this.state.showLoginRegisterMain && !this.props.isOTPEnabled ? (
            <WelcomeBack
              callbackPro={this.welcomeBackCallback.bind(this)}
              resetCallbackPro={this.resetLoginValues.bind(this)}
            />
          ) : null}
          {this.state.showForgotPassword ? (
            <ForgotPassword
              callbackForgotPro={this.forgotPasswordCallback.bind(this)}
              resetCallbackPro={this.resetLoginValues.bind(this)}
            />
          ) : null}
          {this.state.showRegister && this.props.isOTPEnabled ? (
            <RegisterOTP
            couponData={this.props.couponData} 
              callbackRegisterPro={this.registerCallback.bind(this)}
              resetCallbackPro={this.resetLoginValues.bind(this)}
            />
          ) : null}
          {this.state.showRegister && !this.props.isOTPEnabled ? (
             <RegisterModalData
            couponData={this.props.couponData} 
              callbackRegisterPro={this.registerCallback.bind(this)}
              resetCallbackPro={this.resetLoginValues.bind(this)}
            />
          ) : null}
        </ul>
      </li>
   );
  }
}

function mapStateToProps(state) {
  const stateObj = getReleventReduxState(state, 'header');
  const isOtpEnabled = getReleventReduxState(stateObj, 'isOtpEnabled');
  const couponData = getReleventReduxState(stateObj, 'couponData');
  return {
    isOTPEnabled: isOtpEnabled,
    couponData: couponData
  };
}

export default connect(
  mapStateToProps,
)(UserAccInfo);
