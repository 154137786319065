import React from 'react';
import {  Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Tooltip  from 'react-bootstrap/lib/Tooltip';
import Button  from 'react-bootstrap/lib/Button';
import connect from 'react-redux/src/connect/connect'
import ItemImage from './image';
import RibbonTag from './ribbonTag';
import Price from './price';
import Promotions from './promotion';
import InStock from './inStock';
import Wishlist from './wishlist';
import Title from './title';
import { getCookie } from '../../../utils/utilityManager';
import { formatPrice } from '../../../utils/utilityManager';
import {
  addToCart,
  imagePrefix,
} from '../../../../public/constants/constants';
import apiManager from '../../../utils/apiManager';
import {
  updatetMinicart,
  updatetWishListCount,
  resetRemoveFromWishlistFlag,
} from '../../../actions/app/actions';
import {
  getUpdatedMinicartCount,
  removeFromWishlistGlobalAPI,
} from '../../../utils/initialManager';
import {
  createSEOPdpURL,
  isMobile,
} from '../../../utils/utilityManager';
import { triggerProductClickGTEvent } from '../../../utils/gtm';
import "../../../../public/styles/pdpComponent/pdpRatingsAndReviews.scss";

import { TAX_DISCLAIMER } from '../../../constants/app/pdpConstants';
import { parse } from 'path';

class ProductItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onSwatchChange = this.onSwatchChange.bind(this)
    this.state = {
      data: this.props.dataPro,
      colorSwatchSplit: this.props.swatchList.length > 6 ? this.props.swatchList.slice(0, 6) : [],
      colorSwatchFull: this.props.swatchList,
	  rollOver: this.props.dataPro.rollOver,
	  rollOver2: this.props.dataPro.rollOver2,
    imagePath: this.props.imagePath && this.props.imagePath!='' && this.props.imagePath!=null 
    && this.props.imagePath!=undefined ?this.props.imagePath : '',
    plpUnitsSoldInfo1: this.props.plpUnitsSoldInfo1 && this.props.plpUnitsSoldInfo1!='' && 
    this.props.plpUnitsSoldInfo1!=null && this.props.plpUnitsSoldInfo1!=undefined ?this.props.plpUnitsSoldInfo1 : '',
    plpUnitsSoldInfo2: this.props.plpUnitsSoldInfo2 && this.props.plpUnitsSoldInfo2!='' && 
    this.props.plpUnitsSoldInfo2!=null && this.props.plpUnitsSoldInfo2!=undefined ?this.props.plpUnitsSoldInfo2 : '',
    };
  }



  starRating(rating,maxRating,type,ratingGotFlag) {
    // let array=[];
    console.log('rating in PdpComponent.js = '+rating);
    const generateStarsArray=(maxRating) => Array.from({ length: maxRating }, (_, index) => index + 1);
    const starsArray = generateStarsArray(maxRating);
    return (
      <>
      {rating && 
      <div className='rating-stars-and-number-of-ratings-div'>
      <div className='rating-stars-div'>
        {<span className={type=='rating'?'data-star-rating':type=='review'?'data-star-review':''}>
          <i data-star={rating}></i>  
        </span>}
      </div>
      <span className='number-of-ratings'>({rating})
      </span>
        
        
      </div>
      }
      </>
    )
  }

  handleClick(e) {
    e.preventDefault();
    const product = { 
      title: this.state.data.productName,
      thumbnail: this.state.data.thumbnail,
      skuId: this.state.data.uniqueID,
      id: this.state.data.parentUniqueID,
      actualPrice: this.state.data.actualPrice,
      offerPrice: this.state.data.offerPrice,
      masterCategoryID: this.state.data.masterCategoryID,
    };
    this.props.addProduct(product);
  }

  moveToCartClicked = e => {
    e.preventDefault();
    
    const productType = this.state.data.type;

    var data;
    if (productType === 'BundleBean') {
      let orderItem = Array();
      this.state.data.bundleComponents.map(bundleData => {
        orderItem.push({ sku_id: bundleData.uniqueID, quantity: bundleData.quantity.toString() })
      });
      data = {
        orderItem
      };

    }
    else {
      data = {
        orderItem: [
          {
            sku_id: this.state.data.uniqueID,
            quantity: '1',
          },
        ],
      };
    }

    apiManager
      .post(addToCart, data)
      .then(response => {
        getUpdatedMinicartCount(this);
        // this.props.updatetMinicart();
        removeFromWishlistGlobalAPI(this.state.data.uniqueID, this);
        this.props.moveToCartPopUpPro();
      })
      .catch(error => {
      });
  };

  onSwatchChange(e, selectedSwatch) {
    e.preventDefault();
    const selectedItem = this.props.skuList.find((item) => item.swatchColor === selectedSwatch)
    this.setState({
      data: selectedItem,
      rollOver: selectedItem.rollOver,
      rollOver2: selectedItem.rollOver2,
    })
  }

  // showToolTip(){
  //   return()
  // }

  showAllSwatchColors = e => {
    e.preventDefault();
    this.setState({
      colorSwatchSplit: [],
    })
  };

  productClickHandler = (skuData, index) => {
    skuData.category=document.location.pathname.substring(1,document.location.pathname.length);
    triggerProductClickGTEvent(
      skuData,
      this.props.isSearchPathPro.replace('/', ''),
      index + 1,
    );
  }

  render() {

    const imagePath= this.props.imagePath && this.props.imagePath!='' && this.props.imagePath!=null 
    && this.props.imagePath!=undefined ?this.props.imagePath : '';
    const plpUnitsSoldInfo1= this.props.plpUnitsSoldInfo1 && this.props.plpUnitsSoldInfo1!='' && 
    this.props.plpUnitsSoldInfo1!=null && this.props.plpUnitsSoldInfo1!=undefined ?this.props.plpUnitsSoldInfo1 : '';
    const plpUnitsSoldInfo2= this.props.plpUnitsSoldInfo2 && this.props.plpUnitsSoldInfo2!='' && 
    this.props.plpUnitsSoldInfo2!=null && this.props.plpUnitsSoldInfo2!=undefined ?this.props.plpUnitsSoldInfo2 : '';


    const toolTipCompare = <Tooltip id='compare'>
    Compare
  </Tooltip>
    var routePath = createSEOPdpURL(this.state.data.productName, this.state.data.shortDescription, this.state.data.partNumber);
    var swatchFinalData;
	let hoverImg = ``
    let hoverBoxClassName = ``
    hoverImg = `${imagePrefix}${this.state.rollOver2!='' ? this.state.data.thumbnail : this.state.rollOver}`;
    hoverBoxClassName = `hoverBox`
    let compareIcon = '/B2C/static/GREY%20COMPARE%20ICON.svg'
    let compareData = getCookie('compareProduct') ? JSON.parse(getCookie('compareProduct')) : ''
    // console.log(compareData)

    if(compareData.length>0){
      // console.log(compareData[0])
      compareData.map((prod,key)=>{
        if(prod.skuId == this.state.data.uniqueID){
          compareIcon = '/B2C/static/blackCompareIcon.svg'
        }
      })
    //  compareIcon = compareData[0].id == this.state.data.uniqueID ||  compareData[1].id == this.state.data.uniqueID ||  compareData[2].id == this.state.data.uniqueID ? '/B2C/static/BLACK%WHISLIST%20ICON-1.svg' :'/B2C/static/GREY%20COMPARE%20ICON.svg'
    }
    else{
      compareIcon = '/B2C/static/GREY%20COMPARE%20ICON.svg'
    }
   if (this.state.colorSwatchSplit.length !== 0) {
      swatchFinalData = this.state.colorSwatchSplit;
    }
    else {
      swatchFinalData = this.state.colorSwatchFull;
    }
    const userAgent = window.navigator.userAgent.toLowerCase();
	const compareButton = /ipad/.test( userAgent ) ?
  <OverlayTrigger id='compare'
  overlay={toolTipCompare} placement="bottom">
     <Button className="btn-compare" onClick={this.handleClick.bind(this)}><img src={ `${imagePrefix + compareIcon}`} /></Button>
     </OverlayTrigger> : 
     <OverlayTrigger id='compare'
     overlay={toolTipCompare} placement="bottom">
     <Button className="btn-compare" onClick={this.handleClick.bind(this)}><img src={`${imagePrefix + compareIcon}`} /></Button></ OverlayTrigger>;
    return (
      <li className="productlist">
        <div className="prdListData">
          <Link className="link" to={{ pathname: routePath, state: !this.props.isSearchPathPro.includes('/search') ? { breadcrumbData: this.props.breadcrumbDataPro } : undefined}} target="_blank" >
          <ItemImage
            data={this.state.data.rollOver2 ? this.state.data.rollOver2: this.state.data.thumbnail}
            uniqueId={this.state.data.uniqueID}
            partNumber={this.state.data.partNumber}
            productName={this.state.data.productName}
            shortDescription={this.state.data.shortDescription}
            breadcrumbDataPro={this.props.plpBreadcrumbPro}
            isSearchPathPro={this.props.isSearchPathPro}
          />
          <InStock isInStock={this.state.data.inStock} />
          <RibbonTag data={this.state.data.ribbonText}  ribbonColor={this.state.data.ribbonColor}/>
          <div className="product-text">
            <Title
              titlePro={this.state.data.productName}
              descriptionPro={this.state.data.shortDescription}
            />

            {this.state.data && this.state.data.productRating && this.state.data.productRating!='' && this.state.data.productRating!=0 &&
              <div className="plp-rating">
              {
              this.starRating(parseFloat(this.state.data.productRating),parseInt(5),'rating',true)
              }
              </div>
            }

            {this.state.data && this.state.data.unitsSold && this.state.data.unitsSold!='' && this.state.data.unitsSold!=0 &&
                imagePath && imagePath!='' && imagePath!=null && imagePath!=undefined && 
                ((plpUnitsSoldInfo1 && plpUnitsSoldInfo1!='' && plpUnitsSoldInfo1!=null && plpUnitsSoldInfo1!=undefined) ||  
                (plpUnitsSoldInfo2 && plpUnitsSoldInfo2!='' && plpUnitsSoldInfo2!=null && plpUnitsSoldInfo2!=undefined) )&& 
                
                <div className="plp-units-sold">
                  <img src={ `${imagePrefix + imagePath}`} />
                  
                  <div className='plp-units-sold-text'>
                  <span>{plpUnitsSoldInfo1}</span>
                  <span>{this.state.data.unitsSold}</span>
                  <span>{plpUnitsSoldInfo2}</span>

                  </div>
                </div>
            }
            
            <p className="price text">
              <Price
                actualPrice={this.state.data.actualPrice}
                offerPrice={this.state.data.offerPrice}
                discount = {this.state.data.discount}
              />
            </p>
            {this.state.data.discount?<span className="discount-value">You save ₹{formatPrice(parseFloat(this.state.data.actualPrice) - parseFloat(this.state.data.offerPrice))} <span className='discount-percent'>({this.state.data.discount}% OFF)</span></span>:<></>}
            
            <Promotions
              promoData={this.state.data.promotionData}
              discount={this.state.data.discount}
              emi={this.state.data.emiData} 
              offerPrice = {this.state.data.offerPrice}
              />
         </div>
         </Link>
        </div>
        <Link target="_blank" className="link" to={{ pathname: routePath, state: this.props.isSearchPathPro.includes('/furniture') ? { breadcrumbData: this.props.plpBreadcrumbPro } : undefined}}
          onClick={this.productClickHandler.bind(this, this.state.data, this.props.index)}>
          <div className={hoverBoxClassName}>
		  {this.props.coloumnLayout === 4 ? 
            this.state.thumbnail!='' ? <div className='imageHover'><img loading='lazy' effect="blur" src={hoverImg} /></div> : <></> 
            :
            this.state.rollOver2!='' ? <div className='imageHover'><img loading='lazy' effect="blur" src={hoverImg} /></div> : <></> 
            }
           
          </div>
          {this.props.isfromWishlistPro ?
              <button className={this.props.isShareWishlistPro ? 'btn-compare' : isMobile() ? 'mov-to-cart hty' : 'btn-compare'} onClick={this.moveToCartClicked.bind(this)}><b>{this.props.isShareWishlistPro ? 'ADD TO CART' : 'MOVE TO CART'}</b></button> :
              compareButton}
		  <div className = "product-color-block">
			 {this.props.isColorSwatchPro && this.props.swatchList.length > 1 ? <div className="inner-overlay">
              <ul className="colortheme clearfix">

                {swatchFinalData.map((item,index) => {
                  var colorStyle = { backgroundColor: `rgb${item.colorCode}` };
									var name = '';
									var imgUrl = '';
									colorStyle = {
										display: "block",
									}
									var circle = 'display:block';
									var isRadio = false;
                  var boxClass = '';
                  if (item.colorCode) {
										circle = 'circle';
										colorStyle = {
											backgroundColor: `rgb${item.colorCode}`,
                    };
                    name=<div className="imgCircleColor" style={{backgroundColor:`rgb${item.colorCode}`, borderRadius: '50%'}} />;
                  }
                  else if (item.facetImage) {
										circle = 'circleImg';
										imgUrl = item.facetImage;
										name = <img loading='lazy' effect="blur" className="imgCircle" src={`${imagePrefix}${imgUrl}`} />;
									}
                  return (
                    <li key={index} onClick={(e) => this.onSwatchChange(e, item.name)} className={`list ${this.state.data.swatchColor === item.name ? 'active' : ''}`}>
                       <div className={circle=='circle'?'swatches-circle':'swatches-circle-img'} style={colorStyle}>{name}</div>
                    </li>
                  )

                })}

                {/* {this.state.colorSwatchSplit.length !== 0 ? <button className={isMobile() ?'moreSwatchForMobile':'moreSwatch'} onClick={this.showAllSwatchColors.bind(this)}>+ {this.state.colorSwatchFull.length - this.state.colorSwatchSplit.length} more</button> : null} */}
                {this.state.colorSwatchSplit.length !== 0 ?  <li  onClick={this.showAllSwatchColors.bind(this)} className='additional-swatches'><span>+ {this.state.colorSwatchFull.length - this.state.colorSwatchSplit.length}</span></li> : null}
                
              
              </ul>
            </div> : null}
		  </div>
        </Link>
        <Wishlist
          uniqueId={this.state.data.uniqueID}
          isInWishlistPro={this.props.isInWishlist}
          isFromWishlistPro={this.props.isfromWishlistPro}
          history={this.props.history}
        />
      </li>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  { updatetMinicart, updatetWishListCount, resetRemoveFromWishlistFlag },
)(ProductItem);
