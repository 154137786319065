import React from 'react';
import '../../../public/styles/thankyou/thankyou.scss';
import './index';
import {
  homeFurnitureBtnClickTrackerTrigger,
  modularKitchenSubmitTrackerTrigger,
  modularKitchenSubmitTrackerTrigger1,
  gtagHmeKitchenBtnClickTrackerTrigger,
} from '../../utils/campaigns';

class thank extends React.Component {
	 constructor(props) {
    super(props);
    this.state = {
      fbPixelTriggered: false,
    };
  }

  componentDidMount(){
    if(window.location.pathname == '/thank-you-modular' || window.location.pathname == '/thank-you-north' ||
      window.location.pathname == '/thank-you-furniture' || window.location.pathname == '/thank-you-northwest' ||
      window.location.pathname == '/thank-you-northeast' || window.location.pathname == '/thank-you-southatk' ||
      window.location.pathname == '/thank-you-southtk' || window.location.pathname == '/thank-you-east' ||
      window.location.pathname == '/thank-you-west' || window.location.pathname == '/thank-you-central' ||
      window.location.pathname == '/thank-you-northm' || window.location.pathname == '/thank-you-southm' ||
      window.location.pathname == '/thank-you-westm' || window.location.pathname == '/thank-you-eastm')
      {
        gtagHmeKitchenBtnClickTrackerTrigger();
      }
    fbq('init', '971812680435038');
    fbq('track', 'PageView');
    const url = window.location.pathname;
    if(window.location.pathname == '/thank-you-north' || window.location.pathname == '/thank-you-southtk' || window.location.pathname == '/thank-you-northeast'
        || window.location.pathname == '/thank-you-northwest' || window.location.pathname == '/thank-you-east' || window.location.pathname == '/thank-you-southatk' 
        || window.location.pathname == '/thank-you-west'
        ||window.location.pathname == '/thank-you-central' || window.location.pathname == '/thank-you-furniture')
      { 
      homeFurnitureBtnClickTrackerTrigger();
       }else if ((window.location.pathname == '/thank-you-modular' || window.location.pathname == '/thank-you-north' ||
        window.location.pathname == '/thank-you-furniture' || window.location.pathname == '/thank-you-northwest' ||
        window.location.pathname == '/thank-you-northeast' || window.location.pathname == '/thank-you-southatk' ||
        window.location.pathname == '/thank-you-southtk' || window.location.pathname == '/thank-you-east' ||
        window.location.pathname == '/thank-you-west' || window.location.pathname == '/thank-you-central' ||
        window.location.pathname == '/thank-you-northm' || window.location.pathname == '/thank-you-southm' ||
        window.location.pathname == '/thank-you-westm' || window.location.pathname == '/thank-you-eastm') && 
        (localStorage.getItem('mkRequirement') && localStorage.getItem('mkRequirement')=='Home Furniture'))
        {
      modularKitchenSubmitTrackerTrigger1();
        } else{
      modularKitchenSubmitTrackerTrigger();
      }

      if(window.location.pathname == '/thank-you-north' || window.location.pathname == '/thank-you-southtk' || window.location.pathname == '/thank-you-northeast'
        || window.location.pathname == '/thank-you-northwest' || window.location.pathname == '/thank-you-east' || window.location.pathname == '/thank-you-southatk' 
        || window.location.pathname == '/thank-you-west'
        ||window.location.pathname == '/thank-you-central' || 
        window.location.pathname == '/thank-you-furniture'){
            fbq('track', 'furniture thank you page')
      } else{
         fbq('track', 'kitchen thank you page')
      }
  }
  render() {

    return (
      <div className="thankPage">
        <img className="successImg" src="https://www.godrejinterio.com/imagestore/thankyou.png"/>
        <p className="thankTitle"><b>Thank You</b></p>
        <p className="thankInfo">Your request has been submitted.</p>
        <p className="thankInfo">Our representative will reach out to you shortly.</p>
        <a className="btn shopBtn" href='/'>CONTINUE SHOPPING</a>
      </div>
    );
  }
}

export default thank;
