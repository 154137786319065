import React from 'react';
import apiManager from '../../utils/apiManager';
import { cartApplyPromoAPI } from '../../../public/constants/constants';
import {PROMO_CODE_IS_INVALID, GOT_PROMO_CODE } from '../../constants/app/cartConstants';

class PromoField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderID: this.props.orderID,
      promoCode: '',
      error: null,
      promoError: '',
      promoApplied: '',
      applyText:'APPLY',
      applyingText:'APPLYING',
    };
    this.handlePromoCode = this.handlePromoCode.bind(this);
    this.applyPromoCode = this.applyPromoCode.bind(this);
  }

  handlePromoCode(event) {
    this.setState({ promoCode: event.target.value,promoError: '',error: null, });
  }

  applyPromoCode() {
    if (!this.state.promoCode) return null;
    this.props.cartCouponsBlock && this.props.cartCouponsBlock.length > 0 && this.props.cartCouponsBlock.forEach((item1,index1) => {
      if(this.state.promoCode.toUpperCase().startsWith(item1.couponCodeStartsWith)){
        this.setState({
          promoError: this.props.promocodeError,
        });
        return null;
      }

    })
    if(this.state.promoError==''){
    const data = {
      orderId: this.state.orderID,
      promoCode: this.state.promoCode.toUpperCase(),
    };
    this.setState({
      promoApplied:this.state.promoCode,
    });
    apiManager
      .post(cartApplyPromoAPI, data)
      .then(response => {
        this.setState({
          promoCode: response.data.data.promoCode,
          error: null,
        });
        this.props.getCartDetails();
      })
      .catch(error => {
        this.setState({
          error: error.response.data.error,
          isLoading: false,
          promoApplied: '',
        });
      });
    }
  }

  onKeyPress=(event)=>
  {
    if(event.key === 'Enter'){
      this.applyPromoCode();
    }
  }

  render() {
    const { error,promoError } = this.state;
    return (
      <div className="promoField">
        <input
          type="text"
          className="promoInput"
          value={this.state.promoCode}
          onChange={this.handlePromoCode}
          onKeyPress={this.onKeyPress}
          placeholder={GOT_PROMO_CODE}
        />
        <button className="applyBtn" onClick={this.applyPromoCode}>
          {this.state.promoApplied!=''?this.state.applyingText:this.state.applyText}
        </button>

        {promoError && !promoError== '' ?(
          <div className="promoError">{promoError}</div>
        ):!!error && (
          <div className="promoError">{error.error_message || `${PROMO_CODE_IS_INVALID }`}</div>
        )}
      </div>
    );
  }
}

export default PromoField;
