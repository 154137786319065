const HOME_FURNITURE_BTN_TRACKERID = 'AW-10869903464/lffGCKek4KsDEOiglr8o';
const MODULAR_KITCHEN_SUBMIT_TRACKERID = 'AW-10869903464/UpBiCM_34asDEOiglr8o';
const MODULAR_KITCHEN_SUBMIT_TRACKERID1 = 'AW-10869903464/pzEFCNrc8pYaEOiglr8o';
const GTAG_HOME_KITCHEN_TRACKER = 'AW-10869903464';

export const homeFurnitureBtnClickTrackerTrigger = () => {
  googleTagConversionEventTrigger(HOME_FURNITURE_BTN_TRACKERID, () => {});
};

export const modularKitchenSubmitTrackerTrigger = () => {
  googleTagConversionEventTrigger(MODULAR_KITCHEN_SUBMIT_TRACKERID, () => {});
};

export const modularKitchenSubmitTrackerTrigger1 = () => {
  googleTagConversionEventTrigger(MODULAR_KITCHEN_SUBMIT_TRACKERID1, () => { });
};

export const gtagHmeKitchenBtnClickTrackerTrigger = () => {
  googleTagConversionEventTrigger(GTAG_HOME_KITCHEN_TRACKER, () => {});
 
};

const googleTagConversionEventTrigger = (trackerId, callback) => {
  gtag && gtag('event', 'conversion', {
    'send_to': trackerId,
    'event_callback': callback,
  });
};
