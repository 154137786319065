/**
 *
 * PlpContainer
 *
 */

import React, { Suspense} from 'react';
import connect from 'react-redux/src/connect/connect'
import compose from 'redux/src/compose'
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import Pixels from '../../components/Primitives/pixels';
import {
  getReleventReduxState,
  resolveTheFilter,
  formateSearchKeyword,
} from '../../utils/utilityManager';
import { InView } from 'react-intersection-observer';
import '../../../public/styles/plpContainer/plpContainer.scss';
import appCookie from '../../utils/cookie';
import BestSeller from '../../components/BestSelling/bestSelling';
import * as actionCreators from './actions';
import apiManager from '../../utils/apiManager';
import {
  plpSubCatAPI,
  plpAPI,
  espotAPI,
  searchPageAPI,
  imageSearchLocalStorageKey,
  imagePrefix,
} from '../../../public/constants/constants';
import { createPlpItemData,isMobile } from '../../utils/utilityManager';
import { triggerImpressionsGTEvent, unmountImpressionsGTEvent } from '../../utils/gtm';
import GSchemas from '../../components/GSchemas';
const PlpComponent = React.lazy(()=> import('../../components/PlpComponent/index'));
const SubCategories = React.lazy(()=> import('../../components/GlobalComponents/productSubcategories/subCategories'));
const FilterMain = React.lazy(()=> import('../../components/PlpComponent/Filter/filterMain'));
const MarketingTextBanner = React.lazy(()=>import('../../components/PlpComponent/MarketingeTextBanner/marketingTextBanner'));
const DescriptionBanner = React.lazy(()=> import('../../components/PlpComponent/DescriptionBanner/descriptionBanner'));
const Sort = React.lazy(()=> import('../../components/PlpComponent/Sorting/sort'));
const CompContainer = React.lazy(()=>import('./compWidget'));
const RWDSort = React.lazy(()=>import('../../components/PlpComponent/RWD PLP Components/RWDSort'));
const RWDFilterMain = React.lazy(()=> import('../../components/PlpComponent/RWD PLP Components/RWDFilter/RWDFilterMain'));
const Breadcrumb = React.lazy(()=> import('../../components/Breadcrumb/breadcrumb'));

let categoryId;
export class PlpContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plpSubCatData: null,
      marketingTextBannerData: null,
      plpDescriptionData: null,
      plpFaqData: null,
      plpData: [],
      adBannerData: null,
      error: false,
      hasMore: true,
      isLoading: false,
      pageNumber: 1,
      pageSize: 18,
      categoryDetail: true,
      sortValue: this.props.sortingValue,
      filterData: [],
      isCatDetails: true,
      categoyDetails: null,
      productCount: null,
      plpFilter: '',
      plpSorting: '',
      displaySkus: true,
      breadcrumbData: null,
      isLoadingExternal: true,
      // Search Vars
      isFromSearch: this.props.location.pathname,
      searchKeyword: this.props.location.search,
      emptySearchItem: null,
      showBestSeller: null,
      newSearchTrigger: false,
      spellCheckCount: 0,
      // Browser Routing Vars
      browserFilters: [],
      bottomInview:false,
      // RWD Vars
      isMobile: window.innerWidth <= 992,
      imagePath: '',
      plpUnitsSoldInfo1: '',
      plpUnitsSoldInfo2: '',
    };
    this.scrollPrev = null
    this.myRef = React.createRef();
    this.onscroll = this.onscroll.bind(this);
    this.onSearchNoResut = this.onSearchNoResut.bind(this);
  }

  componentWillUnmount() {
    if (this.props.history.location.pathname !== '/compare') {
      appCookie.set('compareProduct', '', 365 * 24 * 60 * 60 * 1000);
      this.props.removeAll();
    }
    removeEventListener('scroll', this.onscroll);
    unmountImpressionsGTEvent();
  }

    fetchUnitsSoldData() {
      apiManager
        .get(`${espotAPI}GI_B2C_Units_Sold`)
        .then(response => {
          if (response.data.data) {

            this.setState({
              imagePath: response.data.data.imagePath || '',
              plpUnitsSoldInfo1: response.data.data.plpUnitsSoldInfo1 || '',
              plpUnitsSoldInfo2: response.data.data.plpUnitsSoldInfo2 || '',
            });

          }
        })
        .catch(error => { });
    }  
  componentDidMount() {
    this.fetchUnitsSoldData();
    categoryId = '';
   
    const path = String(this.props.location.pathname);
    const idStr = this.props.location.pathname.replace('/furniture-', '');
    if (idStr != undefined && idStr !== categoryId) {
      categoryId = idStr;
    }

    const params = new URLSearchParams(this.props.location.search);
    const filterAtt = params.get('filter');
    let filterRoutingURL = '';
    let sortingRoutingURL = '';
    const onlyFilter = [];
    for (const p of params) {
      if (p[0] === 'filter') {
        filterRoutingURL += `${decodeURI(p[1])}&`;
        onlyFilter[p[1]];
      } else if (p[0] === 'sort') {
        sortingRoutingURL = p[1];
      }
    }
    filterRoutingURL = filterRoutingURL.replace(' ', '+');
    const vingg = new URLSearchParams(filterAtt);

    this.setState({
      plpFilter: filterRoutingURL,
      plpSorting: sortingRoutingURL,
      browserFilters: vingg.getAll('facet'),
    });

    this.scrollPrev = window.scrollY
    addEventListener('scroll', e => {this.onscroll(e)});

    this.fetchPLPProductsData();
    if (!this.state.isFromSearch.includes('/search')) {
      this.fetchSubCategoryData();
      this.fetchMarketingTextBannerData();
      this.fetchDescriptionData();
      this.fetchListingFaqs();
    }
  }


  componentWillReceiveProps(nextProps) {
    const params = new URLSearchParams(this.props.location.search);
    if (nextProps.sortingValue !== this.props.sortingValue) {
      const params1 = new URLSearchParams(this.props.location.search);

      params1.set(`sort`, `${nextProps.sortingValue}`);
      const finalMap = params1.toString();
      this.props.history.push({ search: finalMap });
      this.setState({
        plpData: [],
        pageNumber: 1,
        plpSorting: nextProps.sortingValue,
  
      });
      this.fetchPLPProductsData();
    }
    if (nextProps.updatedFilter !== this.props.updatedFilter) {
      const params2 = new URLSearchParams(nextProps.location.search);
      if (nextProps.updatedFilter === '') {
        params2.delete('filter');
      } else {
        params2.set(`filter`, `${encodeURI(nextProps.updatedFilter)}`);
      }
      this.props.history.push({ search: params2.toString(),
      });
      this.setState({
        plpData: [],
        // filterData: [],
        pageNumber: 1,
        // plpFilter: nextProps.updatedFilter,
       
        plpFilter :this.resolveTheFilter(nextProps.updatedFilterArray),
      });
      // this.resolveTheFilter(nextProps.updatedFilterArray);
      this.fetchPLPProductsData();
    }

    if (nextProps.location.pathname.includes('/search')) {
      const paramsNext = new URLSearchParams(nextProps.location.search);
      const keywordNext = paramsNext.get('keyword');
      const paramsThis = new URLSearchParams(this.props.location.search);
      const keywordThis = paramsThis.get('keyword');
      if (keywordNext !== keywordThis) {
        // If New search entered
        this.setState({
          marketingTextBannerData: null,
          plpSubCatData: null,
          plpData: [],
          filterData: [],
          plpFilter: '',
          pageNumber: 1,
          plpDescriptionData: null,
          plpFaqData: null,
          categoryDetail: true,
          isFromSearch: nextProps.location.pathname,
          searchKeyword: nextProps.location.search,
          showBestSeller: false,
          newSearchTrigger: true,
          spellCheckCount: 0,
          emptySearchItem: null,
        });
        categoryId = '';
        this.fetchPLPProductsData();
      } else {
      }
    } else if (nextProps.location.pathname !== this.props.location.pathname) {
      const nextPath = String(nextProps.location.pathname);
      const nextIdStr = nextPath.replace('/furniture-', '')

      if (nextIdStr != undefined && nextIdStr !== categoryId) {
        categoryId = nextIdStr
        this.resetStateVars();
        this.fetchPLPProductsData();
        this.fetchSubCategoryData();
        this.fetchMarketingTextBannerData();
        this.fetchDescriptionData();
        this.fetchListingFaqs();
      }
    }

  }

  resolveTheFilter(updatedFilter) {
    let filterURL = '';
    for (const [key, value] of updatedFilter) {


      filterURL += 'facet=';
      value.map((option, i) => 
      {
        let isThere=false;
        for(const item of this.state.filterData)
        {
          const isFound = item.facetValues.filter((e)=>e.label===option.label);
          if(isFound.length > 0)
            isThere =true;
        }
        if(isThere===true)
          filterURL += option.value;
              
        if (value.length !== i + 1) {
          filterURL += '+';
        }
      });
      filterURL += '&';
    }
    return filterURL;
  }


  remoiveSelection(selectionFacetValue)
  {
    let selectedFacetName;
    const selectedFacetValuesArr = [];
    for (const [key, value] of this.props.updatedFilterArray) {
      var items = [];
      var facetItems = []
      value.map((option, i) => {
        items.push(option.value);
        facetItems.push(option);
      })

      if (items.includes(selectionFacetValue)) {
        selectedFacetName = key;
        items.filter((value, i, arr) => {
          if (value != selectionFacetValue) {
            selectedFacetValuesArr.push(facetItems[i]);
          }
        });
      }
    }

    this.props.onFilterUpdate(selectedFacetValuesArr, selectedFacetName)
  }


  resetStateVars() {
    this.setState({
      plpSubCatData: null,
      marketingTextBannerData: null,
      plpDescriptionData: null,
      plpFaqData: null,
      plpData: [],
      adBannerData: null,
      error: false,
      hasMore: true,
      isLoading: false,
      pageNumber: 1,
      pageSize: 18,
      categoryDetail: true,
      sortValue: this.props.sortingValue,
      filterData: [],
      isCatDetails: true,
      categoyDetails: null,
      productCount: null,
      plpFilter: '',
      plpSorting: '',
      isFromSearch: '',
      searchKeyword: '',
      emptySearchItem: null,
      showBestSeller: null,
      newSearchTrigger: false,
      browserFilters: [],
      spellCheckCount: 0,
    });
  }

  fetchAdBannerData() {
    let adBannerEspotName = `GI_PLP_AD_BANNER`;
    if (categoryId != undefined) {
      adBannerEspotName = `GI_PLP_AD_BANNER_${categoryId.toUpperCase()}`;
    }
    if (this.state.isFromSearch.includes('/search')) {
      adBannerEspotName = 'GI_SEARCH_RESULTS_AD_BANNER';
    }
    apiManager
      .get(`${espotAPI}${adBannerEspotName}`)
      .then(response => {
        if (response.data.data) {
          this.props.onAdBannerIndexUpdate(response.data.data);
          this.setState({ adBannerData: response.data.data });
        }
      })
      .catch(error => { });
  }

  fetchSubCategoryData() {
    apiManager
      .get(plpSubCatAPI + categoryId, {
        headers: {},
      })
      .then(response => {
        if (response.data.data) {
          this.setState({ plpSubCatData: response.data.data });
        }
      })
      .catch(error => { });
  }

  fetchMarketingTextBannerData() {
    apiManager
      .get(`${espotAPI}GI_PLP_HERO_BANNER_${categoryId.toUpperCase()}`)
      .then(response => {
        if (response.data.data) {
          this.setState({
            marketingTextBannerData: response.data.data.bannerList[0].content,
          });
        }
      })
      .catch(error => { });
  }

  fetchPLPProductsData(isFromScroll) {

    this.setState({ isLoading: true, isLoadingExternal: this.state.pageNumber == 1? true: false }, () => {
      let filterURL = this.state.plpFilter
      let urlMaking = plpAPI + categoryId;
      let searchText = null;
      if (this.state.isFromSearch.includes('/search')) {
        const params = new URLSearchParams(this.state.searchKeyword);
        const keyword = params.get('keyword');
        searchText = encodeURIComponent(keyword);
        searchText = formateSearchKeyword(searchText, false)
        urlMaking = searchPageAPI + searchText;
      }
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      if(params.has('filter')){
        this.setState({
          isLoadingExternal: true
        })
        params.forEach((value, key)=>{
          if(key == 'filter'){
            filterURL =decodeURI(value)
          }
        })
      }
      
      const plpURL =
        `${urlMaking}?` +
        `pagenumber=${this.state.pageNumber}&` +
        `pagesize=${this.state.pageSize}&` +
        `orderby=${this.state.plpSorting}&${filterURL}`;
      apiManager
        .get(plpURL, {
          headers: {
            cat_details: this.state.isCatDetails,
            sku_display: String(this.state.displaySkus),
          },
        })
        .then(response => {
          if (response.data.data && response.data.data.landingPage) {
            this.props.history.replace(response.data.data.landingPage);
          }
          if (this.state.browserFilters.length !== 0) {
            this.resolveBrowserFilters(
              response.data.data.facetData,
              this.state.browserFilters,
            );
          }

          if (this.state.isFromSearch.includes('/search')) {
            if (this.state.newSearchTrigger && response.data.data.productList.length !== 0) {
              this.setState({
                emptySearchItem: null,
                showBestSeller: false,
                newSearchTrigger: false,
              });
            }
            else if (response.data.data.productList.length === 0 && this.state.plpData.length === 0) { // && condition to not show the empty search view on scroll last
              this.setState({
                showBestSeller: true,
                emptySearchItem: null,
                newSearchTrigger: false,
              })
            }

            if (response.data.data.spellCheck && response.data.data.spellCheck.length !== 0 && !isFromScroll) {
              this.setState({
                emptySearchItem: this.onSearchNoResut(decodeURI(searchText), response.data.data.spellCheck),
                showBestSeller: false,
                newSearchTrigger: false,
              })
            }
          }

          if (this.state.isCatDetails || !isFromScroll) {
            if (!this.state.isFromSearch.includes('/search')) {
              const coloumnValue = 4;
              this.props.initialValuesUpdate(coloumnValue);
              this.setState({
                plpData: [],
                filterData: [],
                categoryDetail: response.data.data.categoryDetails,
                displaySkus: response.data.data.categoryDetails.displaySkus,
              });
            }
            else {
              this.props.initialValuesUpdate(3);
            }
          }

          if (this.state.isCatDetails) {
            this.fetchAdBannerData();
          } else if (this.state.isFromSearch.includes('/search')) {
            this.fetchAdBannerData();
          }

          if (response.data.data.productList && response.data.data.productList.length > 0) {
            triggerImpressionsGTEvent(
              response.data.data.productList,
              this.props.location.pathname.replace('/', ''),
              this.state.pageNumber,
              this.state.pageSize,
            );
          }
          this.setState({
            plpData: isFromScroll ? [...this.state.plpData, ...response.data.data.productList] : response.data.data.productList,
            productCount: response.data.data.productCount,
            filterData: response.data.data.facetData,
            breadcrumbData: response.data.data.breadCrumbData,
            hasMore: response.data.data.productList.length > 0 ? true: false,
            isLoading: false,
            isLoadingExternal: false,
            isCatDetails: false,
            browserFilters: [],
          });
        })
        .catch(error => {
          if (this.state.isFromSearch.includes('/search')) {
            this.setState({
              showBestSeller: true,
              emptySearchItem: null,
              newSearchTrigger: false,
              isLoading: false,
              isLoadingExternal: false,
              hasMore: false,
            });
          } else {
            this.setState({
              error: error.response ? error.response.data.error.error_message : '',
              isLoading: false,
              isLoadingExternal: false,
            });

          }
        });

    });
  }

  resolveBrowserFilters(filterResponse, browserFilters) {
    const finalBrowserFilter = new Map();
    for (let i = 0; i < browserFilters.length; i++) {
      var reduxFilter = [];
      var name;
      const facetValue = browserFilters[i];
      var splitFacet = facetValue.split(' '); // If more than 1 filter applied from the same Facet
      filterResponse.map((facetItem, index) => {
        facetItem.facetValues.map((innerItem, index) => {
          if (splitFacet.includes(innerItem.value.replace(/\+/g, '%2B')) /* innerItem.value === facetValue */) {
            name = facetItem.facetName;
            innerItem.value = innerItem.value.replace(/\+/g, '%2B');
            reduxFilter.push(innerItem);
          }
        }); // innerItem ended
      }); // facetItem ended

      finalBrowserFilter.set(name, reduxFilter);
    }
    this.props.onBrowserFilterUpdate(finalBrowserFilter);
  }

  fetchDescriptionData() {
    apiManager
      .get(`${espotAPI}GI_PLP_DESCRIPTION_${categoryId.toUpperCase()}`)
      .then(response => {
        if (response.data.data.description) {
          this.setState({ plpDescriptionData: response.data.data });
        }
      })
      .catch(error => {
      });
  }

  fetchListingFaqs = () => {
    apiManager
      .get(`${espotAPI}GI_PLP_FAQS_${categoryId.toUpperCase()}`)
      .then(response => {
        if (response.data.data) {
          this.setState({ plpFaqData: response.data.data });
        }
      })
      .catch(error => {
      });
  };

  onSearchNoResut(searchText, spellCheckArr) {
    if (spellCheckArr) {
      if (spellCheckArr && spellCheckArr.length !== 0) {
        const params = new URLSearchParams(this.props.location.search);
        const keywoard = formateSearchKeyword(params.get('keyword'), false);
        this.setState({
          searchKeyword: `keyword=${spellCheckArr[0]}`,
        });
        const isImageSearched = params.get('imageSearch');
        const searchedImageBase64String = localStorage.getItem(imageSearchLocalStorageKey);
        return (
          <div className="noResultfound">
            {isImageSearched === 'true' && searchedImageBase64String ? (
              <div className="label-noresult">
                <div className="image-search-preview">
                  No results for<img src={searchedImageBase64String} />
                </div>
              </div>
            ) : (
              <div className="label-noresult">
                No results for “{keywoard}”
              </div>
            )}
            <div className="product-serchGuide">
              <div className="label-text">Did you mean: </div>
              <div className="serchlist-button">
                {spellCheckArr.map(item => (
                  <button className='searchitem-button' onClick={() => this.onSpellCheckClick(item)}>{item}</button>
                ))}
              </div>
            </div>
          </div>
        );
      } 
      // Show Best Seller component
      

      return (
        <div className="noResultfound">
          <div className="label-noresult">
            No results for “{formateSearchKeyword(searchText, false)}”
          </div>
          <div className="product-serchGuide">
            <div className="label-text">Did you mean: </div>
            <div className="serchlist-button">
              {spellCheckArr.map(item => (
                <button className='searchitem-button' onClick={() => this.onSpellCheckClick(item)}>{item}</button>
              ))}
            </div>
          </div>
        </div>
      );
    }
  }

  fetchLoadDetailsFromPLPComponent(){
    this.setState({
      pageNumber: this.state.pageNumber + 1 
    },()=>{this.fetchPLPProductsData(true)})
    
  }

  onSpellCheckClick(spellText) {
    this.props.history.push({
      pathname: '/search',
      search: `keyword=${spellText}`,
    });
    this.setState({
      searchKeyword: `keyword=${spellText}`,
      plpData: [],
      filterData: [],
      pageNumber: 1,
    });
    this.fetchPLPProductsData();
  }

  onscroll = (e) => {
    const {
      state: { error, isLoading, hasMore },
    } = this;

     if(!isMobile()){
      var header = document.getElementById("header");
      var sticky = header.offsetTop;
      const window = e.currentTarget;
      if (this.scrollPrev > window.scrollY) {
        
        if (window.scrollY > sticky) {
          header.classList.add("header-sticky");
        } else {
          header.classList.remove("header-sticky");
        }

      } else if (this.scrollPrev < window.scrollY) {
         // console.log("scrolling down");
          header.classList.remove("header-sticky");
      }
      this.scrollPrev = window.scrollY;
    }else{
      const window = e.currentTarget;
      var header = document.getElementById("mob-header");
      var sticky = header.offsetTop;
      if (this.scrollPrev > window.scrollY) {
        
        if (window.scrollY > sticky) {
          header.classList.add("mob-header-sticky");
        } else {
          header.classList.remove("mob-header-sticky");
        }

      } else if (this.scrollPrev < window.scrollY) {
        //  console.log("scrolling down");
          header.classList.remove("mob-header-sticky");
      }
      this.scrollPrev = window.scrollY;
    }


    if (error || isLoading || !hasMore) return;


    // console.log(windowOffsetHeight)
    if (this.state.bottomInview) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
      // console.log(windowOffsetHeight-800)
      this.fetchPLPProductsData(true);
    }
  };

  render() {
    const {
      error,
      hasMore,
      isLoading,
      plpData,
      marketingTextBannerData,
      plpSubCatData,
      adBannerData,
      filterData,
      isLoadingExternal,
      imagePath,
      plpUnitsSoldInfo1,
      plpUnitsSoldInfo2
    } = this.state;

    let marketingBanner;
    let itemData = '';
    if (marketingTextBannerData != null) {
      marketingBanner = (
	  <div><Suspense fallback = {<></>}>
          <MarketingTextBanner bannerDataPro={marketingTextBannerData} />
		      </Suspense></ div>
      );
    }

    let subCategories;
    if (plpSubCatData != null) {
      subCategories = (
	   <div><Suspense fallback = {<></>}>
          <SubCategories subCategoryData={this.state.plpSubCatData} />
        </Suspense></div>
      );
    }

	  let filterItem;
    if (filterData.length != 0 && !this.state.isMobile) {

      filterItem = <div  className="inner-filter"><Suspense fallback = {<></>}> <FilterMain filterDataPro={filterData} /> </Suspense></div>;
    }


    let plpProducts;
    if (plpData.length != 0) {
      itemData = createPlpItemData(plpData);
      plpProducts = (
	  <>
	  {isMobile() ? (
            <></>
          ) : (
            <div className="filter filter-new ">{filterItem}</div>
          )}
		    <div className='inner-container'><Suspense fallback = {<></>}>
            <PlpComponent
            fetchPLPProductsData = {this.fetchLoadDetailsFromPLPComponent.bind(this)}
              plpDataPro={this.state.plpData}
              adBannerDataPro={adBannerData}
              catId={this.state.categoryDetail.uniqueID}
              history={this.props.history}
              isSearchPathPro={this.props.location.pathname}
              plpBreadcrumbPro={this.state.breadcrumbData}
              showSkuPro={
                !this.state.isFromSearch.includes('/search')
                  ? this.state.categoryDetail.displaySkus
                  : true
              }
              imagePath= {imagePath}
              plpUnitsSoldInfo1= {plpUnitsSoldInfo1}
              plpUnitsSoldInfo2= {plpUnitsSoldInfo2}
            />
            <InView as="div" style={{opacity: '0'}} onChange={(inView, entry) => this.setState({bottomInview: inView})} >
            </InView>
          </Suspense>
          {isLoading && (
          <div className="lazyloading-Indicator">
            <span>Loading more products <img
              id="me"
              className="loadingImg"
              alt='Lazy Loader'
              src={`${imagePrefix}/B2C/static/plpAssests/loadingNew.svg`
              }
            /></span>
           
          </div>
        )}
          </div>
       </>
      );
    }

  

    let sortItem;
    if (!this.state.isMobile && this.state.productCount !== null && this.state.productCount !== 0) {
      sortItem =  <div><Suspense fallback = {<></>}> <Sort sortingIndexPro={this.state.plpSorting} /> </Suspense></div>
    }

    let descriptionItem;
    if (this.state.plpDescriptionData != null) {
      descriptionItem = (
	   <div><Suspense fallback = {<></>}>
            {/* <InView as="div" onChange={(inView, entry) => this.setState({bottomInview: inView})} > */}
          <DescriptionBanner
            descriptionDataPro={this.state.plpDescriptionData}
            ref={divElement => (this.divElement = divElement)}
            isPlp
          />
          {/* </InView> */}
        </Suspense></div>
      );
    }

    let titleItem = null;
    if (this.state.categoryDetail !== null) {
      titleItem = (
        <h1 className="headingTitle">
          {this.state.categoryDetail.categoryName}
        </h1>
      );
    }
    const params = new URLSearchParams(this.state.searchKeyword);
    let keywoard = params.get('keyword');
    const paramsRoute = new URLSearchParams(this.props.location.search);
    const keywoardRoute = paramsRoute.get('keyword');
    const isImageSearched = params.get('imageSearch');
    let searchedImagePreview = null;
    if (this.state.isFromSearch.includes('/search') && isImageSearched === 'true') {
      const searchedImageBase64String = localStorage.getItem(imageSearchLocalStorageKey);
      if (searchedImageBase64String) {
        searchedImagePreview = (
          <div className="image-search-preview">
            <img src={searchedImageBase64String} />
          </div>
        );
      }
    }

    if (this.state.isFromSearch.includes('/search') && plpData.length != 0) {
      keywoard = formateSearchKeyword(params.get('keyword'), false);
      if (searchedImagePreview) {
        titleItem = (
          <div className="searchresult">
            <h3 className="headingTitleFlat">
              Results for {searchedImagePreview}
            </h3>
          </div>
        );
      } else {
        titleItem = (
          <div className="searchresult">
            <h3 className="headingTitleFlat">
              Results for <span className="headingTitleSearch">"{keywoard}"</span>
            </h3>
          </div>
        );
      }
    }

    let productCountItem = null;
    if (this.state.productCount !== null && plpData.length != 0 && this.state.productCount) {
      productCountItem = (
        <div className="headingSubTitle">
          ({this.state.productCount} Products)
        </div>
      );
    }

    let breadcrumbItem = null;
    if (this.state.breadcrumbData !== null && plpData.length != 0 && this.state.breadcrumbData !== undefined && this.state.breadcrumbData.length !== 0) {
      breadcrumbItem = (
        <div><Suspense fallback = {<></>}> <Breadcrumb plpBreadcrumbPro={this.state.breadcrumbData} /> </Suspense></div>
      );
    }
    else if (this.state.isFromSearch.includes('/search')) {
      breadcrumbItem = (
        <div><Suspense fallback = {<></>}> <Breadcrumb isFromSearchPro /> </Suspense></div>
      );
    }

    const gSchemasList = [];
    if (this.state.isFromSearch.includes('/search')) {
      gSchemasList.push({
        type: 'search',
        searchTerm: keywoard,
      });
    }
    
    if (this.state.plpFaqData !== null && this.state.plpFaqData.length > 0) {
      gSchemasList.push({
        type: 'listingFaq',
        faqs: this.state.plpFaqData,
      });
    }

    return (
      <>
        {gSchemasList &&
          gSchemasList.length !== 0 && <GSchemas schemas={gSchemasList} />}
        {/* <ContentEspot espotName={'GI_PIXEL_PLP_BODY_START' + (this.props.match.params.id ? '_' + this.props.match.params.id.toUpperCase().replace(' ', '') : '')} /> */}
        <Pixels
          description={this.state.categoryDetail.metaDescription}
          title={this.state.isFromSearch.includes('/search') ? 'Experience our products first hand at your nearest Godrej Interio store' : this.state.categoryDetail.pageTitle} 
          alt={this.state.categoryDetail.imageAltText}
          image={this.state.categoryDetail.fullImage}
        />
        {/* <script type="application/ld+json" nonce="383143991673915569" id="jsonLD">
          {`[{"@context":"http://schema.org","@type":"ItemList","itemListElement":${JSON.stringify(itemData)}}]`}
        </script> */}
          {breadcrumbItem}
        {marketingBanner}
      
        
        {this.state.emptySearchItem !== null
          ? this.state.emptySearchItem
          : null}
        {this.state.showBestSeller ? (
          <>
            <div>
              <div className="noResultfound">
                <div className="label-noresult">
                  No results for {searchedImagePreview ? (
                    <>
                      <br />
                      {searchedImagePreview}
                    </>
                  ) : `“${formateSearchKeyword(keywoardRoute, false)}”`}
                </div>
              </div>
              <div className="Search-bestseller container">
                <BestSeller />
              </div>
            </div>
          </>
        ) : null}

        <section className="plpCategories" >
          <div className="container" id = 'scrollFilter'>
            <div className="row">
              <div style={{ float: "left" }}>
                {titleItem}
                {productCountItem}
              </div>
              {subCategories}
              
            </div>
            <div className="row no-padding" />
          </div>
          <div className="filterWrapper clearfix" >
               {isMobile() ? <></> : 
                  <div className="sort">{sortItem}</div>
                }
              </div>
              {isLoadingExternal && (
          <div className="lazyloading-Indicator">
            <span>Loading more products <img
              id="me"
              className="loadingImg"
              alt='Lazy Loader'
              src={`${imagePrefix}/B2C/static/plpAssests/loadingNew.svg`
              }
            /></span>
           
          </div>
        )}
          <div className={this.state.productCount === 0 ? "container2":"container2-new"}>{plpProducts}</div>
         
        </section>

        {error ? window.location.assign('/'):''}
        {/* {error && <div className='noProductFound'>{error}</div>} */}
       


        {this.state.productCount === 0 &&
          !this.state.isFromSearch.includes('/search') ? (
            window.location.assign('/')
            // <div className="noProductFound">No Products Found</div>
          ) : null}

        {descriptionItem}
		 <div><Suspense fallback = {<></>}>
          <CompContainer />
        </Suspense></div>

        {this.state.isMobile && this.state.productCount !== null && this.state.productCount !== 0 ?
          <div className='sortfilter'>
		   <div><Suspense fallback = {<></>}>
              <RWDSort sortingIndexPro={this.state.plpSorting} />
              <RWDFilterMain
                filterDataPro={filterData}
                isSKUPro={this.state.displaySkus}
                categoryIdPro={this.state.categoryDetail.uniqueID}
                isSearchPathPro={this.props.location.pathname}
                searchKeywordPro={keywoard}
              />
            </Suspense></div>
          </div> : null}
        {/* <ContentEspot espotName={'GI_PIXEL_PLP_BODY_END' + (this.props.match.params.id ? '_' + this.props.match.params.id.toUpperCase().replace(' ', '') : '')} /> */}

      </>
    );
  }
}

/* ----------------------------------------   REDUX HANDLERS   -------------------------------------  */
const mapStateToProps = state => {
  const stateObj = getReleventReduxState(state, 'plpContainer');
  
  return {
    ctr: stateObj.counter,
    updatedFilter: resolveTheFilter(stateObj.updateFilter),
    updatedFilterArray:stateObj.updateFilter,
    sortingValue: stateObj.sortingValue,
    reduxTrigger: true,
  };
};

const mapDispatchToProps = dispatch => ({
  initialValuesUpdate: coloumn => dispatch(actionCreators.updateInitialValues(coloumn)),
  onIncrementCounter: () => dispatch(actionCreators.increment()),
  onAdBannerIndexUpdate: adBannerData =>
    dispatch(actionCreators.adBannerDataAction(adBannerData)),
  plpReduxStateReset: () => dispatch(actionCreators.resetPLPReduxState()),
  onFilterUpdate: (updatedArr, facetName) =>
    dispatch(actionCreators.filter(updatedArr, facetName)),
  onBrowserFilterUpdate: browserFilter =>
    dispatch(actionCreators.browserFilter(browserFilter)),
  removeAll: () => dispatch(actionCreators.RemoveAll()),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'plpContainer', reducer });
const withSaga = injectSaga({ key: 'plpContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(PlpContainer);
